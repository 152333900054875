<template>
    <div>
        <CBadge v-if="statusColor(row.item.invoice_status_id)"
                :style="'background-color:'+statusColor(row.item.invoice_status_id)">{{ row.item.invoice_status }}
        </CBadge>
        <CBadge v-else :color="statusClass(row.item.invoice_status)">{{ row.item.invoice_status }}</CBadge>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        'row': [Object],
    },
    methods: {
        ...mapGetters('CommonData', ['getEntityStatusColors']),
        statusColor(invoice_status_id) {
            if (Object.hasOwn(this.getEntityStatusColors(), invoice_status_id)) {
                return this.getEntityStatusColors()[invoice_status_id];
            } else {
                return false;
            }
        },
        statusClass(status) {
            if (status == 'overpaid') {
                return 'warning';
            } else if (status == 'partial_paid') {
                return 'info'
            } else if (status == 'paid') {
                return 'success'
            } else if (status == 'unpaid') {
                return 'danger'
            } else {
                return 'light';
            }
        },
    }
}
</script>